import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation hook
import { getSites } from '../api';
import { Site } from '../types';
import { Box, Drawer, Button, List, ListItem, ListItemIcon, ListItemText, Typography, CssBaseline, AppBar, Toolbar } from '@mui/material';
import { ReactComponent as BinanceIcon } from '../icons/Binance.svg';
import { ReactComponent as CoinbaseIcon } from '../icons/Coinbase.svg'; 
import { ReactComponent as KrakenIcon } from '../icons/Kraken.svg'; 
import { ReactComponent as KucoinIcon } from '../icons/Kucoin.svg'; 
import { ReactComponent as NdaxIcon } from '../icons/Ndax.svg'; 
import { ReactComponent as RevolutIcon } from '../icons/Revolut.svg'; 
import { ReactComponent as SkrillIcon } from '../icons/Skrill.svg'; 
import { ReactComponent as WiseIcon } from '../icons/Wise.svg'; 
import { ReactComponent as SygnumIcon } from '../icons/Sygnum.svg';
import { ReactComponent as ShakepayIcon } from '../icons/Shakepay.svg';
import { ReactComponent as Xlogo } from '../icons/Xlogo2.svg'; 

import { useNavigate } from 'react-router-dom';


const drawerWidth = 240;

const SiteList: React.FC = () => {
  const navigate = useNavigate();

  const [sites, setSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useState<string | null>("Skrill"); // State for selected item
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from localStorage
    navigate('/login'); // Navigate to the login page
  };

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const sites = await getSites();
        const uniqueSites = sites.filter((site, index, self) => 
          index === self.findIndex(s => s.Name === site.Name)
        );
        setSites(uniqueSites);
      } catch (error) {
        console.error('Error fetching sites:', error);
      }
    };

    fetchSites();
  }, []);

  useEffect(() => {
    // Extract the site name from the pathname
    const pathname = location.pathname;
    const siteName = pathname.substring(1); // Remove leading slash

    setSelectedSite(siteName || "Skrill"); // Update selected site state
  }, [location.pathname]);

  const getIcon = (siteName: string) => {
    switch (siteName) {
      case 'Skrill':
        return <SkrillIcon width={42} height={42} />; 
      case 'Kucoin':
        return <KucoinIcon width={36} height={36} />;        
      case 'Wise':
        return <WiseIcon width={35} height={35} />;
      case 'Binance':
        return <BinanceIcon width={44} height={45} />;
      case 'Kraken':
        return <KrakenIcon width={42} height={42} />; 
      case 'Ndax':
        return <NdaxIcon width={39} height={39} />;        
      case 'Revolut':
        return <RevolutIcon width={42} height={42} />;
      case 'Coinbase':
        return <CoinbaseIcon width={42} height={42} />;                   
      case 'Sygnum':
        return <SygnumIcon width={46} height={45} />;         
      case 'Shakepay':
        return <ShakepayIcon width={42} height={42} />;      
      default:
        return null; // You can add a default icon or leave it null
    }
  };

  return (
<Box sx={{ display: 'flex' }}>
  <CssBaseline />
  <AppBar
    position="fixed"
    sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      backgroundColor: '#333333', // Match sidebar color
      color: '#FFFFFF', // White text for contrast
    }}
  >
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography sx= {{ fontWeight: '450', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.2)' }} variant="h4" noWrap component="div">
      ManageX
      </Typography>
      <Button
          color="inherit"
          onClick={handleLogout}
          sx={{
            color: 'white', // White text
            padding: '8px 20px', // Custom padding
            fontWeight: 'bold', // Bold text for emphasis
            textTransform: 'uppercase', // Uppercase text
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
            transition: 'background-color 0.3s, transform 0.2s', // Smooth transition effects
            '&:hover': {
              transform: 'scale(1.05)', // Slight scaling effect on hover
            },
          }}
        >
          Logout
        </Button>
    </Toolbar>
  </AppBar>
      <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#333333', // Dark background color
              color: '#FFFFFF', // White text for contrast
              flexDirection: 'column', // Arrange items vertically
            },
          }}
        >
        <Toolbar />
        <Box >
          <List>
            {sites.map((site) => (
              <ListItem
                button
                key={site.ID}
                component={Link}
                to={`/${site.Name}`}
                selected={selectedSite === site.Name}
              >
                <ListItemIcon>
                  {getIcon(site.Name)}
                </ListItemIcon>
                <ListItemText primary={site.Name} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 2,
              marginTop: 'auto', 
            }}
          >
            <Xlogo width="85%" height="85%" /> 
          </Box>
      </Drawer>
      <Box >
        <Toolbar />
      </Box>
    </Box>
  );
};

export default SiteList;
