import React, { useState, useEffect } from 'react';
import { Site } from '../types';
import { updateSite } from '../api';
import { Button, TextField, Snackbar, Box, Select, MenuItem, InputLabel, FormControl, Alert } from '@mui/material';

interface SiteFormProps {
  site: Site;
  groups: number[];
  selectedGroup: number;
  onGroupChange: (group: number) => void;
}

const SiteForm: React.FC<SiteFormProps> = ({ site, groups, selectedGroup, onGroupChange }) => {
  const [parameters, setParameters] = useState<Record<string, string>>(() => {
    const { Description, ...rest } = site.Parameters; // Destructure to separate description
    return { Description, ...rest }; // Return an object with description first
  });
  
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const { Description, ...rest } = site.Parameters; // Destructure again in useEffect
    setParameters({ Description, ...rest }); // Preserve order when updating
  }, [site.Parameters]);

  const handleChange = (key: string, value: string) => {
    setParameters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isEmpty = Object.values(parameters).some((value) => value.trim() === ''); // Check if any value is empty
    if (isEmpty) {
      setMessage('Please fill in all fields');
      setOpen(true);
    } else {
      try {
        const updatedSite = { ...site, Parameters: parameters };
        await updateSite(site.ID, updatedSite);
        setMessage('Update successful');
        setOpen(true);
      } catch (error) {
        console.error('Error updating site:', error);
        setMessage('Update failed');
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <InputLabel id="group-select-label">Group</InputLabel>
        <Select
          labelId="group-select-label"
          value={selectedGroup}
          onChange={(e) => onGroupChange(Number(e.target.value))}
        >
          {groups.map((group) => (
            <MenuItem key={group} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {Object.entries(parameters).map(([key, value]) => (
        <TextField
          key={key}
          label={key}
          value={value}
          onChange={(e) => handleChange(key, e.target.value)} // Update the parameter value on change
          fullWidth
          margin="normal"
        />
      ))}
      <Box mt={2}>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={message === 'Update successful' ? 'success' : 'error'}>
          {message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default SiteForm;
