import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSiteByName, getSites, deleteSite } from '../api'; // Import deleteSite function
import { Site } from '../types';
import SiteForm from './SiteForm';
import SiteList from './SiteList'; // Import SiteList component
import { Typography, Grid, ListItemIcon, Button, Box } from '@mui/material'; // Import Box component
import CoinbaseImage from '../images/coinbase.jpg'; // Import your image
import RevolutImage from '../images/revolut.jpg'; // Import your image
import { ReactComponent as BinanceIcon } from '../icons/Binance.svg';
import { ReactComponent as CoinbaseIcon } from '../icons/Coinbase.svg'; 
import { ReactComponent as KrakenIcon } from '../icons/Kraken.svg'; 
import { ReactComponent as KucoinIcon } from '../icons/Kucoin.svg'; 
import { ReactComponent as NdaxIcon } from '../icons/Ndax.svg'; 
import { ReactComponent as RevolutIcon } from '../icons/Revolut.svg'; 
import { ReactComponent as SkrillIcon } from '../icons/Skrill.svg'; 
import { ReactComponent as WiseIcon } from '../icons/Wise.svg'; 
import { Divider } from '@mui/material';

const SitePage: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const navigate = useNavigate();
  const [sites, setSites] = useState<Site[]>([]);
  const [site, setSite] = useState<Site | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<number>(1); // Default group to 1

  const getIcon = (siteName: string) => {
    switch (siteName) {
      case 'Skrill':
        return <SkrillIcon width={42} height={42} />; 
      case 'Kucoin':
        return <KucoinIcon width={36} height={36} />;        
      case 'Wise':
        return <WiseIcon width={35} height={35} />;
      case 'Binance':
        return <BinanceIcon width={44} height={45} />;
      case 'Kraken':
        return <KrakenIcon width={42} height={42} />; 
      case 'Ndax':
        return <NdaxIcon width={39} height={39} />;        
      case 'Revolut':
        return <RevolutIcon width={42} height={42} />;
      case 'Coinbase':
        return <CoinbaseIcon width={42} height={42} />;                   
      default:
        return null; // You can add a default icon or leave it null
    }
  };

  // Fetch all sites data
  const fetchSites = async () => {
    try {
      const sites = await getSites();
      setSites(sites);
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };

  // Fetch site data by name and group
  const fetchSite = async () => {
    try {
      const site = await getSiteByName(name ?? "Skrill", selectedGroup);
      setSite(site);
    } catch (error) {
      console.error(`Error fetching site with name ${site?.Name} and group ${selectedGroup}:`, error);
    }
  };

  // Fetch site data on component mount and when the route or selected group changes
  useEffect(() => {
    fetchSites();
    fetchSite();
  }, [name, selectedGroup]);

  // Handle group change
  const handleGroupChange = (group: number) => {
    setSelectedGroup(group);
  };

  const getImage = (siteName: string) => {
    switch (siteName) {
      case 'Coinbase':
        return CoinbaseImage; 
      case 'Revolut':
        return RevolutImage;  
      default:
        return ""; 
    }
  };

  // Handle delete site
  const handleDelete = async () => {
    if (site) {
      try {
        await deleteSite(site.Name, selectedGroup);
        navigate('/');
      } catch (error) {
        console.error(`Error deleting site with name ${site.Name} and group ${selectedGroup}:`, error);
      }
    }
  };

  // Render loading message while site data is being fetched
  if (!site) {
    return <Typography>Loading...</Typography>;
  }

  // Get unique groups from the sites
  const groups = Array.from(new Set(sites.map(site => site.Group)));

  return (
    <Grid container sx={{
      paddingTop: '30px',
      paddingLeft: '10px',
      backgroundColor: '#f7f7f7'}}>
      <Grid item>
        <SiteList />
      </Grid>
      <Grid item xs={8}>
      <Typography 
  variant="h3" 
  style={{  fontWeight: '550', letterSpacing: '1.5px', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.2)' }}
>
  
  {site.Name}
</Typography>
        <SiteForm site={site} groups={groups} selectedGroup={selectedGroup} onGroupChange={handleGroupChange} />
        <Box mt={2}>
          <Button variant="contained" color="secondary" onClick={handleDelete}>
            Delete Group
          </Button>
        </Box>
        <Box mt={4}>
          <Divider />
        </Box>
        <Box mt={1}>
        {getImage(site.Name) && getImage(site.Name).trim() !== "" && ( 
        <Typography
          style={{
            fontSize: '1.1rem', 
            color: '#333',
            lineHeight: 1.5, 
            fontStyle: 'italic',
            marginTop: '16px',
            marginBottom: '16px', 
          }}
        >
          Below is an image of the site, which can help you visually understand how the different parameters in the form correspond to elements on the site
        </Typography>)}
        </Box>
        <Box mt={4}>
          <img src={getImage(site.Name)} width="50%" /> {/* Add your image here */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SitePage;
